<template>
  <Page :hasBg="false" style="background: #fff;">
    <NavBar title="核验详情" @clickLeft="handleRouterBack" :customClickLeft="true" :leftArrow="true"> </NavBar>
    <div v-if="formData.id" class="p-24 font-500">
      <div class="flex items-center justify-between">
        <div>
          核验状态：<span :class="`text-${tagMap[formData.auditStatus].color}`">{{
            tagMap[formData.auditStatus].title
          }}</span>
        </div>
        <div
          v-if="formData.auditStatus === 3 || formData.auditStatus === 1"
          class="text-12 px-16 py-8"
          style="border: 1px solid #333; border-radius: 100px;"
          @click="handleReaudit"
        >
          重新提交
        </div>
      </div>
      <div v-if="formData.refuseReason && formData.auditStatus === 3" class="mt-16">
        驳回原因：{{ formData.refuseReason }}
      </div>
      <div class="flex justify-between items-center mt-24">
        <span class="font-700 text-16">核验场景</span>
        <span>{{ usageMap[formData.usage] }}</span>
      </div>
      <div class="font-700 text-16 mt-24">
        核验对象
      </div>
      <div v-if="formData.usage === 1">
        <div class="flex items-center justify-between py-8 mt-16" style="border-bottom: 1px solid #e2e2e2;">
          <div>司机姓名</div>
          <div>{{ formData.driverName }}</div>
        </div>
        <div class="flex items-center justify-between py-8 mt-8" style="border-bottom: 1px solid #e2e2e2;">
          <div>司机身份证号</div>
          <div>{{ formData.driverIdCard }}</div>
        </div>
        <div v-if="idCardUrlList.length" class="mt-16">
          <div>身份证照片</div>
          <div class="mt-8">
            <img
              v-for="(url, i) in idCardUrlList"
              :key="i"
              class="mr-8"
              style="height: 1rem; width: 1rem"
              :src="url"
              @click="handleImagePreview(idCardUrlList, i)"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center justify-between py-8 mt-16" style="border-bottom: 1px solid #e2e2e2;">
          <div>车牌号</div>
          <div>{{ formData.carLicensePlate }}</div>
        </div>
        <div v-if="licenseUrlList.length" class="mt-24">
          <div>行驶证主副页照片</div>
          <div class="mt-8">
            <img
              v-for="(url, i) in licenseUrlList"
              :key="i"
              class="mr-8"
              style="height: 1rem; width: 1rem"
              :src="url"
              @click="handleImagePreview(licenseUrlList, i)"
            />
          </div>
        </div>
      </div>
      <div v-if="evidenceUrlList.length">
        <div class="font-700 text-16 mt-24">证明材料</div>
        <div class="mt-8">
          <img
            v-for="(url, i) in evidenceUrlList"
            :key="i"
            class="mr-8"
            style="height: 1rem; width: 1rem"
            :src="url"
            @click="handleImagePreview(evidenceUrlList, i)"
          />
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { defineComponent, computed, watch, ref } from '@vue/composition-api';
import NavBar from '@/components/NavBar/index.vue';
import { useIdVerifiedStore } from '@/store/idVerify';
import { tagMap, usageMap } from '../constants';
import { getThumbnailFileTemporaryUrlListApi } from '@/api/common';
import { PageEnum } from '@/enums/pageEnum';
import { ImagePreview } from 'vant';

export default defineComponent({
  name: 'VerifyDetail',
  components: {
    NavBar
  },
  setup(props, ctx) {
    const idVertifiedStore = useIdVerifiedStore();
    const formData = computed(() => idVertifiedStore.verifiedInfo);
    const idCardUrlList = ref([]);
    const licenseUrlList = ref([]);
    const evidenceUrlList = ref([]);
    const handleRouterBack = () => {
      ctx.root.$routerBack.call(ctx.root._router);
    };

    const getUrlsList = async () => {
      const attachmentFileUrlsArr = formData.value.attachmentFileUrls.split(',');
      if (formData.value.usage === 1) {
        const idCardFileArr = [formData.value.driverIdCardFrontFilename, formData.value.driverIdCardBackFilename];
        const filenames = idCardFileArr.filter(v => v !== null);
        if (filenames.length) {
          const res = await getThumbnailFileTemporaryUrlListApi({ filenames });
          const newArr = [];
          filenames.forEach(key => {
            if (res.data?.[key]?.url) {
              newArr.push(res.data?.[key]?.url);
            }
          });
          idCardUrlList.value = newArr;
        }
      } else if (formData.value.drivingLicenseFilename) {
        const res = await getThumbnailFileTemporaryUrlListApi({ filenames: [formData.value.drivingLicenseFilename] });
        licenseUrlList.value = [res.data?.[formData.value.drivingLicenseFilename]?.url];
      }
      if (attachmentFileUrlsArr?.length) {
        const newArr = [];
        const res = await getThumbnailFileTemporaryUrlListApi({ filenames: attachmentFileUrlsArr });
        attachmentFileUrlsArr.forEach(key => {
          if (res.data?.[key]?.url) {
            newArr.push(res.data?.[key]?.url);
          }
        });
        evidenceUrlList.value = newArr;
      }
    };

    const handleReaudit = () => {
      const { $router } = ctx.root;
      $router.push(PageEnum.ID_VERIFY_PAGE);
    };

    const handleImagePreview = (data, idx) => {
      ImagePreview({
        images: data,
        startPosition: idx
      });
    };

    watch(
      () => idVertifiedStore.verifiedInfo?.id,
      id => {
        if (id) {
          getUrlsList();
        }
      },
      { immediate: true }
    );

    return {
      tagMap,
      usageMap,
      formData,
      idCardUrlList,
      licenseUrlList,
      evidenceUrlList,
      handleReaudit,
      handleRouterBack,
      handleImagePreview
    };
  }
});
</script>
<style lang="less">
.self-image {
  height: 1.76rem;
}
.detail-form {
  line-height: 0.24rem;
  padding: 0.16rem 0;
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  &.no-border {
    border-bottom: none;
  }
  .text_muted {
    min-width: 0.7rem;
  }
  .time-block {
    div {
      width: 1.55rem;
      text-align: center;
      height: 0.4rem;
      border: 1px solid #bcbcbc;
      border: 1px solid rgba(188, 188, 188, 0.5);
      border-radius: 5px;
      line-height: 0.4rem;
    }
  }
}
.id-card {
  .van-uploader__preview {
    width: 1.63rem;
    height: 1.22rem;
  }
}
</style>
